@import "vars";

@mixin select-item-text-style {
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: $brownLight2;
}

@mixin product-weight-style {
  font-size: 15px;
  line-height: 1;
  font-weight: 400;
  color: $brownLight;
}
