// https://google-webfonts-helper.herokuapp.com/fonts/roboto-condensed?subsets=cyrillic,cyrillic-ext,latin,latin-ext

/* roboto-condensed-300 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-300.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-300italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  src: url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-300italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 400;
  src: url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-700italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 700;
  src: url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('roboto-condensed-v24-latin-ext_latin_cyrillic-ext_cyrillic-700italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
