$base_font: 'Roboto Condensed', sans-serif;
$base_size: 20px;
$base_lh: 1.172;
$font_weight_bold: 700;

//colors
$black: #000000;
$white: #fff;
$bgColor: #FBF9F6;
$gray: #ECEAE9;
$grayDark: #B6ACA9;
$grayDark2: #999999;
$brown: #483027;
$brownLight: #C8C0BE;
$brownLight2: #A49793;
$orange: #FD8100;
$orangeLight: #ffd166;
$green: #7FC21A;
$greenLight: #ECF5D9;
$red: #ED422F;

$base_shadow: 0px 6px 22px rgba(244, 229, 221, 0.366848);
$base_border-radius: 12px;


$disabled_button_background_color: $gray;

// top bar for qr-based web app
$qrwebapp_language_flag_height: 32px;
$qrwebapp_top_bar_padding_top: 15px;
$qrwebapp_top_bar_border_bottom_height: 1px;
$qrwebapp_top_bar_border_style: $qrwebapp_top_bar_border_bottom_height solid #eceae9;
$qrwebapp_top_bar_height_total: $qrwebapp_top_bar_padding_top * 2 + $qrwebapp_language_flag_height + $qrwebapp_top_bar_border_bottom_height;
