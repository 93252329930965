@import "../../../node_modules/primeicons/primeicons.css";
@import "../../../node_modules/primeng/resources/themes/mdc-light-indigo/theme.css";
@import "../../../node_modules/primeng/resources/primeng.css";

@import "../../../shared-styles/menu/vars";
@import "../../../shared-styles/menu/base";
@import "../../../shared-styles/menu/mixins";

@import "./assets/fontello/css/fontello.css";
@import "./assets/flags/flags32-both";
@import "./assets/fonts/roboto-condensed/index";

.flag {
  &.uk {
    @include ua-flag
  }

  &.en {
    @include gb-flag
  }
}

//custom checkbox / radio
.custom-checkbox {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  min-height: 44px;

  &__label-text {
    @include select-item-text-style;

    margin-bottom: 0;
  }

  &__modifier-price {
    @include product-weight-style;
  }

  &__text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    margin-bottom: 0;
    padding-right: 16px;
  }

  &__control {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border: 1px solid $grayDark2;

    &::before {
      content: '';
      display: block;
      transform: scale(0);
      transition: all .2s linear;
    }
  }

  input[type="radio"] + .custom-checkbox__control {
    border-radius: 50%;

    &::before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $orange;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    display: none;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  input[type=checkbox] + .custom-checkbox__control {
    &::before {
      width: 100%;
      height: 100%;
      background: url("../../../shared-assets/menu/img/check.svg") no-repeat center;
      background-size: contain;
    }
  }

  input:checked + .custom-checkbox__control {
    border-color: $orange;
  }

  input[type=radio]:checked + .custom-checkbox__control,
  input[type=checkbox]:checked + .custom-checkbox__control {
    &::before {
      transform: scale(1);
    }
  }

}


.p-dialog-bottom {
  .p-dialog {
    margin-bottom: 0!important;
  }
}

.vertical {

  .content-box {
    max-width: 100%;
  }
  
  .nav {
    width: 100% !important;
    height: 80px !important;
    flex-direction: row !important;
    border-bottom: 1px solid $orange;

    .table-number , .nav__item {
      height: 80px !important;
    }

    .nav__item:not(.table-number):after {
      width: 1px;
      height: 60px;
      bottom: 10px;
      left: 100%;
      transform: translateX(-50%);
    }

  }

}