@import "vars";
@import "utils";

*,
::after,
::before {
  margin: 0;
  padding: 0;
  //font-size: $base_size;
  //font-weight: 700;
  font-style: normal;
  //line-height: $base_lh;
  //color: $brown;
  box-sizing: border-box;
}

html {
  //background-color: $bgColor;
  font-family: $base_font;
}

h1, h2 {
  font-size: 36px;
  line-height: $base_lh;
}

h3 {
  font-size: $base_size;
  font-weight: 700;
  line-height: $base_lh;
}

button {
  background: none;
  border-radius: 0;
  border: none;
  outline: none;
  cursor: pointer;

  &[disabled="disabled"]{
    cursor: not-allowed;
  }
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
}

ul {
  list-style: none;
}

label {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.primary-btn {
  padding: 14px 30px;
  background: $orange;
  color: $white;
  border-radius: $base_border-radius;
  font-family: $base_font;
  font-weight: $font_weight_bold;
}

.secondary-btn {
  padding: 14px 30px;
  color: $black;
  font-family: $base_font;
  font-weight: $font_weight_bold;
}


.styled-modal-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: $orange;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255,0,0,0.4);
  }
}
