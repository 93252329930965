.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-wrapper {
  display: flex;
}

.content-box {
  flex-grow: 1;
  max-width: calc(100vw - 100px);
  height: 100vh;
}
